<!--
 * @Version: 2.0
 * @Author: chengweijia-tel:18702108979
 * @Date: 2022-12-06 14:21:46
 * @LastEditTime: 2023-06-27 14:07:17
 * @Description: 
-->
<template>
  <div class="items">
    <div class="left item">
      <ul>
        <li
          :class="[
            { active: (item.list && leftActive == index) || (!item.list && activeIds.some((v) => v.key == item.key)) },
            {
              hasChild: !item.list
            }
          ]"
          v-for="(item, index) in list1"
          :key="index"
          @click="handleLeftClick(item, index)"
        >
          <van-badge
            v-if="activeIds.length && activeIds.filter((v) => v.parent == item.key).length"
            :content="activeIds.length && activeIds.filter((v) => v.parent == item.key).length"
            color="#1989fa"
          >
            {{ item.value }}
          </van-badge>
          <div v-else>
            {{ item.value }}
            <van-icon v-if="!item.list && activeIds.some((v) => v.key == item.key)" name="success" />
          </div>
        </li>
      </ul>
    </div>
    <div class="right item">
      <ul>
        <li
          :class="activeIds.some((v) => v.key == item.key) ? 'active' : ''"
          v-for="(item, index) in list2"
          :key="index"
          @click="handleRightClick(item, index)"
        >
          {{ item.value }}
          <van-icon v-if="activeIds.some((v) => v.key == item.key)" name="success" />
        </li>
      </ul>
    </div>
  </div>
  <div class="buttons">
    <van-button size="small" block round @click="handleReset">重置</van-button>
    <van-button size="small" block round @click="handleConfirm" color="#5d89ff">
      确认 {{ activeIds.length ? `(${activeIds.length})` : '' }}
    </van-button>
  </div>
</template>
<script lang="js">
import { computed, defineComponent, getCurrentInstance, onMounted, reactive, toRefs, onBeforeUnmount, watch, onActivated } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  components: {},
  props: {
    type: Number,
    value: Array,
    positionType: String
  },
  emits: ['change'],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance()
    const { type, value, positionType } = toRefs(props)

    const router = useRouter()
    const state = reactive({
      list1: [],
      list2: [],
      leftActive: 0,
      activeIds: []
    })

    onMounted(() => {
      getPostType()
    })

    const getPostType = () => {
      proxy.$http.position.getDictionaryCascade({ groupName: 'post_type_name', type: String(props.type) }).then((res) => {
        state.list1 = res
        state.leftActive = 0
        if (state.list1.length) {
          state.list2 = state.list1[0].list
        }
      })
    }

    const handleLeftClick = (item, index) => {
      state.leftActive = index
      state.list2 = item.list
      const idx = state.activeIds.findIndex((v) => v.key == item.key)
      if (!item.list) {
        if (idx == -1) {
          state.activeIds.push(item)
        } else {
          state.activeIds.splice(idx, 1)
        }
      }
    }

    const handleRightClick = (item, index) => {
      const idx = state.activeIds.findIndex((v) => v.key == item.key)
      if (idx == -1) {
        state.activeIds.push(item)
      } else {
        state.activeIds.splice(idx, 1)
      }
    }

    const handleReset = () => {
      state.activeIds = []
    }

    const handleConfirm = () => {
      emit('change', state.activeIds)
    }

    return {
      ...toRefs(state),
      handleLeftClick,
      handleRightClick,
      handleReset,
      handleConfirm
    }
  }
})
</script>
<style lang="scss" scoped>
.items {
  display: flex;
  font-size: 0.26rem;
  color: #323233;
  height: 4.5rem;
  .item {
    flex: 1;
    height: 100%;
    overflow: auto;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 5px;
      height: 5px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      border: 1px solid #fff;
      background-color: rgba(0, 0, 0, 0.1);
    }

    ul li {
      padding: 0.3rem 0.1rem 0.3rem 0.2rem;
      .van-icon {
        position: absolute;
        right: 3px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    &.left {
      ul li {
        background: #f7f8fa;
        position: relative;
        &.active {
          font-weight: bold;
          background: #fff;
          &:before {
            position: absolute;
            top: 50%;
            left: 0;
            width: 0.1rem;
            height: 0.28rem;
            background-color: #1989fa;
            transform: translateY(-50%);
            content: '';
          }
        }
        &.active.hasChild {
          font-weight: bold;
          color: #1989fa;
          background: #f7f8fa;
          &:before {
            width: 0;
            height: 0;
          }
        }
      }
    }
    &.right {
      ul li {
        position: relative;
        &.active {
          font-weight: bold;
          color: #1989fa;
        }
      }
    }
  }
}
.buttons {
  display: flex;
  padding: 0.1rem;
  height: 0.8rem;
  box-sizing: border-box;
  button {
    flex: 1;
    margin: 0 0.1rem;
    height: 100%;
  }
}
</style>
