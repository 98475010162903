
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  getCurrentInstance,
  ComponentInternalInstance,
  ref,
  watch
} from 'vue'
import html2canvas from 'html2canvas'

interface stateOption {
  swiperVisible: boolean
  posterList: any
  imgUrl: any
  list: any
  activeSwiper: number
  qrcode: string
}

export default defineComponent({
  props: ['list', 'recruitType'],
  setup(props, { emit }) {
    const { proxy }: { proxy: any } = getCurrentInstance() as ComponentInternalInstance
    const { list } = toRefs(props)
    const imageRef = ref(null)

    const state = reactive({
      swiperVisible: true,
      posterList: [],
      imgUrl: null,
      list: props.list,
      recruitType: props.recruitType,
      activeSwiper: 0,
      qrcode: null
    }) as stateOption

    watch(list, (nv, ov) => {
      state.list = nv
      url2QRcode()
    })

    /**
     * 获取二维码
     */
    const url2QRcode = () => {
      const postIds = props.list.length && props.list.map((v) => v.id).toString()
      const host = `${location.origin}${location.pathname}`
      // pt {string} postType 职位类别
      let pt = props.list.length && props.list[0].positionTypeId
      const url = `${host}?postIds=${postIds}&pt=${pt}`
      proxy.$http.base.url2QRcode({ url }).then((res) => {
        state.qrcode = res.link
      })
    }
    /**
     * 分享
     */
    const handleShareSelect = (val) => {
      console.log(val)
    }

    /**
     * 生成海报
     */
    const handlePosterSelect = () => {
      console.log(imageRef)
      const loading = proxy.$loading
      loading.show('生成中...')
      html2canvas(imageRef.value, {
        useCORS: true, // 使用跨域
        scrollY: 0,
        scrollX: 0
      })
        .then((canvas) => {
          console.log(`canvas`, canvas)
          state.imgUrl = canvas.toDataURL('image/png')
        })
        .finally(() => {
          loading.hide()
        })
    }

    /**
     * 监听swiper
     *
     */
    const handleSwiperChange = (index) => {
      console.log(`index`, index)
      state.activeSwiper = index
    }

    /**
     * 关闭海报
     *
     */
    const handlePosterClose = () => {
      state.imgUrl = null
    }

    onMounted(() => {
      console.log(`onMounted`, state.list)
      url2QRcode()
    })

    return {
      ...toRefs(state),
      handlePosterSelect,
      handleSwiperChange,
      handlePosterClose,
      imageRef
    }
  }
})
