<!--
 * @Version: 2.0
 * @Author: chengweijia-tel:18702108979
 * @Date: 2022-12-06 14:21:46
 * @LastEditTime: 2022-12-29 12:09:32
 * @Description: 
-->
<template>
  <div class="items">
    <div class="left item">
      <ul>
        <li
          :class="leftActive == index ? 'active' : ''"
          v-for="(item, index) in list1"
          :key="index"
          @click="handleLeftClick(item, index)"
        >
          <van-badge
            v-if="activeIds.length && activeIds.filter((v) => v.codeGrandParent == item.code).length"
            :content="activeIds.length && activeIds.filter((v) => v.codeGrandParent == item.code).length"
            color="#1989fa"
          >
            {{ item.name }}
          </van-badge>
          <div v-else>
            {{ item.name }}
          </div>
        </li>
      </ul>
    </div>
    <div class="center item">
      <ul>
        <li
          :class="centerActive == index ? 'active' : ''"
          v-for="(item, index) in list2"
          :key="index"
          @click="handleCenterClick(item, index)"
        >
          <van-badge :dot="activeIds.some((v) => v.codeParent == item.code)" color="#1989fa">
            {{ item.name }}
          </van-badge>
        </li>
      </ul>
    </div>
    <div class="right item">
      <ul>
        <li
          :class="activeIds.some((v) => v.code == item.code) ? 'active' : ''"
          v-for="(item, index) in list3"
          :key="index"
          @click="handleRightClick(item, index)"
        >
          {{ item.name }}
          <van-icon v-if="activeIds.some((v) => v.code == item.code)" name="success" />
        </li>
      </ul>
    </div>
  </div>
  <div class="buttons">
    <van-button size="small" block round @click="handleReset">重置</van-button>
    <van-button size="small" block round @click="handleConfirm" color="#5d89ff">
      确认 {{ activeIds.length ? `(${activeIds.length})` : '' }}
    </van-button>
  </div>
</template>
<script>
import { computed, defineComponent, getCurrentInstance, onMounted, reactive, toRefs, onBeforeUnmount } from 'vue'
export default defineComponent({
  components: {},
  props: {
    type: String
  },
  emits: ['change'],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance()
    const state = reactive({
      list1: [],
      list2: [],
      list3: [],
      leftActive: 0,
      centerActive: null,
      activeIds: []
    })
    onMounted(() => {
      console.log(`onMounted`, props.type)
      getRegionInfo()
    })

    const getRegionInfo = () => {
      let type = proxy.$Global.recruiteTypeEnum[props.type]
      proxy.$http.base.getWorkplace({ type }).then((res) => {
        state.list1 = res.children
        if (state.list1.length) {
          state.list2 = state.list1[0].children
          if (state.list2.length) {
            state.list3 = state.list2[0].children.map((element) => {
              return {
                ...element,
                codeGrandParent: state.list2[0].codeParent
              }
            })
          }
        }
        state.centerActive = 0
        state.leftActive = 0
      })
    }

    const handleLeftClick = (item, index) => {
      state.leftActive = index
      state.list2 = item.children
      if (state.list2.length) {
        state.list3 = state.list2[0].children.map((element) => {
          return {
            ...element,
            codeGrandParent: state.list2[0].codeParent
          }
        })
      } else {
        state.list3 = []
      }
      state.centerActive = 0
    }

    const handleCenterClick = (item, index) => {
      if (item.children.length) {
        state.list3 = item.children.map((element) => {
          return {
            ...element,
            codeGrandParent: item.codeParent
          }
        })
      }
      state.centerActive = index
    }

    const handleRightClick = (item, index) => {
      const idx = state.activeIds.findIndex((v) => v.code == item.code)
      console.log(`idx`, idx)
      console.log(`item`, item)
      if (idx == -1) {
        state.activeIds.push(item)
      } else {
        state.activeIds.splice(idx, 1)
      }
      console.log(`state.activeIds`, state.activeIds)
    }

    const handleReset = () => {
      state.activeIds = []
    }

    const handleConfirm = () => {
      emit('change', state.activeIds)
    }

    return {
      ...toRefs(state),
      handleLeftClick,
      handleCenterClick,
      handleRightClick,
      handleReset,
      handleConfirm
    }
  }
})
</script>
<style lang="scss" scoped>
.items {
  display: flex;
  font-size: 0.26rem;
  color: #323233;
  height: 4.5rem;
  .item {
    flex: 1;
    height: 100%;
    overflow: auto;
    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 5px;
      height: 5px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      border: 1px solid #fff;
      background-color: rgba(0, 0, 0, 0.1);
    }

    ul li {
      padding: 0.3rem 0.1rem 0.3rem 0.2rem;
    }
    &.left {
      ul li {
        background: #f7f8fa;
        position: relative;
        &.active {
          background: #fff;
          font-weight: bold;
          &:before {
            position: absolute;
            top: 50%;
            left: 0;
            width: 0.1rem;
            height: 0.28rem;
            background-color: #1989fa;
            transform: translateY(-50%);
            content: '';
          }
        }
      }
    }
    &.center {
      ul li {
        position: relative;
        font-weight: bold;
        color: #333;
        &.active {
          font-weight: bold;
          color: #1989fa;
        }
      }
    }
    &.right {
      ul li {
        position: relative;
        &.active {
          font-weight: bold;
          color: #1989fa;
        }
        .van-icon {
          position: absolute;
          right: 3px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}
.buttons {
  display: flex;
  padding: 0.1rem;
  height: 0.8rem;
  box-sizing: border-box;
  button {
    flex: 1;
    margin: 0 0.1rem;
    height: 100%;
  }
}
</style>
