<template>
  <van-list v-model="loading" :finished="!hasNextPage" finished-text="暂无更多职位" @load="handleLoad" :offset="10">
    <!-- <van-cell v-for="item in list" :key="item" :title="item" /> -->

    <!-- <div class="position" id="position" v-infinite-scroll="handleLoad" :infinite-scroll-distance="100"> -->
    <zswitch></zswitch>
    <h5-banner activeType="0"></h5-banner>

    <div class="p-search">
      <el-input
        maxlength="20"
        v-model="queryString"
        placeholder="请输入职位关键词"
        class="search"
        @keydown.enter="handleInputChange"
      >
        <template #append>
          <div class="search_btn" @click="handleInputChange"></div>
        </template>
      </el-input>
    </div>

    <div style="position: relative; background: #fff">
      <div v-if="type == 'blue'" style="width: 6rem">
        <van-tabs v-model:active="activeTab" @click-tab="handleBluePostTypeChange">
          <van-tab v-for="item in bluePostType" :key="item.key" :name="item.key" :title="item.value"></van-tab>
        </van-tabs>
      </div>
      <div :style="[type == 'blue' ? `width: 1.6rem; position: absolute; top: 0; right: 0` : ``]">
        <van-dropdown-menu>
          <van-dropdown-item v-if="type != 'blue'" ref="postTypeRef">
            <template #title>
              <img src="@/assets/h5/position_type.png" class="tab-icon" />
              职位类别
            </template>
            <post-type-select
              v-if="componentVisible"
              :value="form.positionTypeIds"
              :type="recruitType"
              @change="handlePostTypeChange"
            ></post-type-select>
          </van-dropdown-item>
          <van-dropdown-item title="工作地点" ref="regionRef">
            <template v-if="type != 'blue'" #title>
              <img src="@/assets/h5/position_place.png" class="tab-icon" />
              工作地点
            </template>
            <template v-else #title style="color: #333">地点</template>
            <template #default>
              <region-select v-if="componentVisible" :type="type" @change="handleRegionChange"></region-select>
            </template>
          </van-dropdown-item>
        </van-dropdown-menu>
      </div>
    </div>

    <div class="p-list" v-if="positionList.length">
      <div class="p-item" v-for="(item, index) in positionList" :key="index">
        <div class="p-item__checkbox" v-show="checkboxVisible">
          <van-checkbox v-model="item.checked"></van-checkbox>
        </div>
        <div class="p-item__body" @click="pushDetail(item)">
          <div :class="[item.isHotJob == 1 ? 'isHotJob' : '']"></div>
          <div class="p-item__title">{{ item.postNameOut }}</div>
          <div class="p-item__content">
            {{ item.deptNameStr && item.deptNameStr.split('-')[0] }}｜{{
              item.workPlaceNameStr && item.workPlaceNameStr.split('-')[1]
            }}｜{{ item.positionTypeName }}
          </div>
          <div class="p-item__time">{{ $dayjs(item.gmtModified).format('YYYY-MM-DD') }}</div>
        </div>
      </div>
      <!-- <div class="loading">
        <van-loading v-show="hasNextPage" color="#999" />
        <div v-show="!hasNextPage">暂无更多职位</div>
      </div> -->
    </div>
    <div v-else>
      <z-empty></z-empty>
    </div>
    <div class="share" @click="checkboxVisible = !checkboxVisible"></div>
    <div v-show="checkboxVisible" class="p-bottom" @click="handleShare">生成海报</div>
    <van-popup v-model:show="posterVisible" round closeable position="bottom">
      <generate-poster :list="selectedPosition"></generate-poster>
    </van-popup>
    <!-- </div> -->
  </van-list>
</template>

<script lang="js">
import { ref, computed, defineComponent, getCurrentInstance, reactive, toRefs,  onActivated, onMounted, nextTick, watch } from 'vue'
import { useRouter, useRoute, onBeforeRouteLeave, onBeforeRouteUpdate } from 'vue-router'
import { ZMessage } from '@/utils/confirm'
import zswitch from '@/components/layout/z-switch.vue'
import ZEmpty from '@/components/layout/z-empty.vue'
import RegionSelect from '@/views/position-list/components/region-select.vue'
import PostTypeSelect from '@/views/position-list/components/post-type-select.vue'
import GeneratePoster from '@/views/position-list/components/generate-long-poster.vue'
import H5Banner from '@/components/banner/banner-h5.vue'
export default defineComponent({
  components: {
    zswitch,
    ZEmpty,
    RegionSelect,
    PostTypeSelect,
    GeneratePoster,
    H5Banner
  },
  props: {
    type: String
  },
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance()
    const postTypeRef = ref(null)
    const regionRef = ref(null)
    const router = useRouter()
    const route = useRoute()
    const recruitType = route.meta.recruitType

    const state = reactive({
      form: {
        workPlaceIds: [],
        positionTypeIds: [],
        schoolObject: []
      },
      type: props.type,
      positionList: [], //拿取的所有职位数据
      queryString: null,
      pageNum: 1,
      loading: false,
      hasNextPage: false,
      // 是否显示职位选择框
      checkboxVisible: false,
      // 是否显示海报
      posterVisible: false,
      // 已选职位
      selectedPosition: [],
      postIds: [],
      // 蓝领职位类别
      bluePostType: [],
      activeTab: null,
      positionType: null,
      recruitType,
      componentVisible: false
    })

    onActivated(() => {
      // true不需要刷新 false需要刷新
      if (!sessionStorage.getItem('positionKeepAlive')) {
        handlePageInit()
      }
    })

    onMounted(() => {
      sessionStorage.removeItem('positionKeepAlive')
    })

    /**
     * 页面初始化
     *
    */
    const handlePageInit = () => {
      // 列表清空
      state.pageNum = 1
      state.positionList = []
      state.hasNextPage = false

      // 选中tab重置（一线）
      state.activeTab = null

      // 职位类别选中重置
      state.form.positionTypeIds = []

      // 工作地点清空
      state.form.workPlaceIds = []

      // 职位id清空（海报）
      state.postIds = []

      // 搜索关键词重置
      if (route.query.search) {
        state.queryString = route.query.search
      }

      /**
       * -------------以上为清空-------------
       * -------------以下为赋值-------------
      */

      // 选中对应职位类别(校招)
      // if (state.type == 'CampusPosition' && route.query.positionType) {
      //   state.form.positionTypeIds.push(route.query.positionType)
      // }

      // 选中对应招聘对象(校招)
      if (state.type == 'CampusPosition' && route.query.object) {
        console.log(`route.query.object`, route.query.object)
        state.form.schoolObject = [route.query.object]
      }

      // 通过扫海报进来多个职位（社招/校招/一线） ?postIds=201,309
      if (route.query.postIds) {
        state.postIds = route.query.postIds.split(',')
      }

      // 选中对应tab(一线)
      if (state.type == 'blue') {
        getBluePostionType()
        if (route.query.postIds && route.query.pt) {
          state.activeTab = route.query.pt
        } else {
          state.form.positionTypeIds = ['40']
        }
      }
      state.componentVisible = true
      getPositionList()
    }

    const queryName = computed(() => {
      return route.name
    })

    /**
     * 获取蓝领职位类别
     *
     */
    const getBluePostionType = () => {
      proxy.$http.position.getDictionaryCascade({ groupName: 'post_type_name', type: '3' }).then((res) => {
        state.bluePostType = res
      })
    }

    const getPositionList = async () => {
      const params = {
        queryString: state.queryString,
        queryPostNameString: null,
        workPlaceIds: state.form.workPlaceIds,
        workPlaceFlag: state.form.workPlaceIds.includes(`0`) ? 1 : null,
        positionTypeIds: recruitType != 2 ? state.form.positionTypeIds : null,
        schoolPositionTypeIds: recruitType == 2 ? state.form.positionTypeIds : null,
        schoolObject: state.form.schoolObject,
        postType: recruitType,
        pageNum: state.pageNum,
        pageSize: 20,
        postIds: state.postIds
      }
      let res = await proxy.$http.position.getPostInfoPageList(params);
      state.loading = false
      state.positionList = [...state.positionList, ...res.list]
      state.hasNextPage = res.hasNextPage
      return state.positionList
    }
    const pushDetail = (item) => {
      router.push({ name: 'PositionDetail', query: { id: item.id } })
    }

    const handleInputChange = () => {
      state.pageNum = 1
      state.positionList = []
      if (recruitType == 3) {
        if (state.queryString !== null && state.queryString !== '') {
          state.form.positionTypeIds = null
        } else {
          state.activeTab = '40'
          state.form.positionTypeIds = ['40']
        }
      }
      getPositionList().then((res) =>{
        if (recruitType == 3 && res && res.length) {
          if (state.queryString !== null && state.queryString !== '') {
            state.activeTab = res[0].positionTypeId
            state.form.positionTypeIds = res[0].positionTypeId
          } else {
            state.activeTab = '40'
            state.form.positionTypeIds = ['40']
          }
        }
      })
    }

    const onRefresh = () => {
      state.hasNextPage = false
      state.pageNum = 1
      state.positionList = []
      getPositionList()
    }

    /**
     * 选择职位类别
     */
    const handlePostTypeChange = (val) => {
      let positionTypeIds = val.map((v) => v.key)
      state.form.positionTypeIds = positionTypeIds
      postTypeRef.value.toggle()
      onRefresh()
    }

    /**
     * 选择工作地点
     *
     */
    const handleRegionChange = (val) => {
      let workPlaceIds = val.map((v) => v.code)
      state.form.workPlaceIds = workPlaceIds
      regionRef.value.toggle()
      onRefresh()
    }

    /**
     * 分享
     */
    const handleShare = () => {
      state.selectedPosition = state.positionList.filter((v) => {
        return v.checked == true
      })
      if (state.selectedPosition.length > 6) {
        ZMessage({
          message: `最多可选择6个职位, 已选${state.selectedPosition.length}个`,
          type: 'error'
        })
        return false
      }
      state.posterVisible = true
    }

    const handleBluePostTypeChange = (val) => {
      state.form.positionTypeIds = [val.name]
      state.pageNum = 1
      state.positionList = []
      getPositionList()
    }

    const handleLoad = () => {
      if (state.hasNextPage) {
        state.pageNum++
        getPositionList()
      }
    }

    /**
     * 离开
     *
     */
    onBeforeRouteLeave((to, from, next) => {
      if (to.name == 'PositionDetail') {
        sessionStorage.setItem('positionKeepAlive', true)
        state.componentVisible = true
      } else {
        sessionStorage.removeItem('positionKeepAlive')
        state.componentVisible = false
      }
      next()
    })

    return {
      getPositionList,
      ...toRefs(state),
      pushDetail,
      // scrollbottom,
      queryName,
      handleInputChange,
      onRefresh,
      handlePostTypeChange,
      handleRegionChange,
      postTypeRef,
      regionRef,
      handleShare,
      handleBluePostTypeChange,
      handleLoad
    }
  }
})
</script>
<style lang="scss" scoped>
.p-search {
  position: relative;
  // top: 3.8rem;
  z-index: 10;
  width: 7.15rem !important;
  height: 0.83rem !important;
  font-size: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}
::v-deep .el-input-group {
  background: #fff;
  border-radius: 0.5rem;
  box-shadow: 0rem 0.08rem 0.63rem 0rem rgba(0, 0, 0, 0.1);
  line-height: 0.83rem;
  padding-left: 0.18rem;
  box-sizing: border-box;
  margin: -0.33rem auto 0;
}
::v-deep .el-input__inner {
  width: 80%;
  border: none !important;
  height: 0.83rem;
  margin-top: 0;
  line-height: 0.83rem;
  background: transparent;
}
.search_btn {
  width: 0.58rem;
  height: 0.58rem;
  border-radius: 50%;
  background: url(~@/assets/h5/search.png);
  background-size: cover;
  background-repeat: no-repeat;
}
::v-deep .el-input-group__append {
  border: none;
  background: none;
  height: 0.83rem;
  padding-right: 0;
  width: 0.83rem;
}
.select {
  width: 100%;
  height: 1rem;
  border-top: 0.04rem solid #f7f8fc;
  display: flex;
  background: #fff;
  .select_btn {
    width: 50%;
    height: 100%;
    line-height: 1rem;
    text-align: center;
    font-size: 0.29rem;
    color: #333333;
    display: flex;
    justify-content: center;
    div {
      width: 0.4rem;
      height: 0.4rem;
      margin-top: 0.3rem;
      margin-right: 0.15rem;
    }
  }
  .select_btn:nth-child(1) {
    div {
      background: url(~@/assets/h5/position_place.png);
      background-size: cover;
    }
  }
  .select_btn:nth-child(1) {
    div {
      background: url(~@/assets/h5/position_place.png);
      background-size: cover;
    }
  }
  .select_btn:nth-child(2) {
    div {
      background: url(~@/assets/h5/position_type.png);
      background-size: cover;
    }
  }
}
.p-list {
  width: 100%;
  box-sizing: border-box;
  background: #f7f8fc;
  padding: 0 0.2rem 0.2rem 0.2rem;
  overflow: hidden;
  .p-item {
    display: flex;
    align-items: center;
    margin-top: 0.25rem;
    width: 100%;
    .p-item__checkbox {
      padding: 0 0.2rem 0 0;
    }
    .p-item__body {
      width: 100%;
      background: #ffffff;
      box-shadow: 0rem 0.08rem 0.42rem 0rem rgba(0, 0, 0, 0.06);
      border-radius: 0.25rem;
      padding: 0.33rem 0.33rem 0.2rem;
      box-sizing: border-box;
      position: relative;
    }
    .p-item__title {
      font-size: 0.33rem;
      font-weight: 600;
      color: #333333;
    }
    .p-item__content {
      font-size: 0.26rem;
      font-weight: 500;
      color: #333333;
      margin-top: 0.22rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .p-item__time {
      font-size: 0.29rem;
      font-weight: 400;
      color: #999999;
      margin-top: 0.25rem;
    }
  }
}
.place_pop {
  width: 100%;
  height: 100vh;
  background: #f4f5f9;
  position: relative;
  overflow: scroll;
  .p_bottom {
    width: 100%;
    height: 1.17rem;
    background: #fff;
    padding: 0.17rem 0.33rem;
    position: fixed;
    bottom: 0;
    box-sizing: border-box;
    display: flex;
    .p_btn {
      width: 3.5rem;
      height: 0.83rem;
      background: #3693ff;
      border-radius: 0.42rem;
      text-align: center;
      line-height: 0.83rem;
      font-size: 0.33rem;
      color: #ffffff;
    }
  }
  .chosed {
    width: 100%;
    border-bottom: 0.02rem solid #f7f8fc;
    background: #fff;
    padding: 0.25rem 0.33rem;
    padding-right: 0;
    padding-top: 0;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    .chosed_option {
      height: 0.67rem;
      line-height: 0.67rem;
      box-sizing: border-box;
      padding: 0 0.21rem;
      text-align: center;
      min-width: 1.5rem;
      background: rgba(181, 194, 231, 0.15);
      border-radius: 0.39rem;
      margin-right: 0.29rem;
      font-size: 0.29rem;
      color: #3693ff;
      position: relative;
      margin-top: 0.25rem;
    }
    .chosed_Icon {
      font-size: 0.29rem;
      position: absolute;
      right: -0.1rem;
      top: -0.08rem;
    }
  }
  .P_list {
    width: 100%;
    padding: 0.33rem;
    padding-right: 0;
    background: #fff;
    box-sizing: border-box;
    box-shadow: 0rem 0.08rem 0.42rem 0rem rgba(0, 0, 0, 0.06);
  }
  .P_childLists {
    width: calc(100% - 0.66rem);
    box-sizing: border-box;

    overflow: hidden;
    box-shadow: 0rem 0.08rem 0.42rem 0rem rgba(0, 0, 0, 0.06);
    border-radius: 0.25rem;
    margin: 0.25rem 0.33rem;
    margin-bottom: 1.3rem;
    background: #fff;
    .P_childList {
      width: 100%;
      padding: 0 0.33rem;
      box-sizing: border-box;

      .P_child {
        width: 100%;
        padding-bottom: 0.33rem;
        box-sizing: border-box;
        border-bottom: 0.02rem solid #f2f3f4;
        overflow: hidden;
      }
    }
  }
}
.list_title {
  margin-left: 0.09rem;
  font-size: 0.29rem;
  font-weight: 500;
  color: #333333;
  // margin-left: 0.5rem;
}
.P_options {
  display: flex;
  width: 100%;
  overflow: hidden;
  flex-wrap: wrap;
}
.P_option {
  height: 0.67rem;
  line-height: 0.67rem;
  box-sizing: border-box;
  padding: 0 0.21rem;
  text-align: center;
  min-width: 1.5rem;
  background: rgba(181, 194, 231, 0.15);
  border-radius: 0.39rem;
  margin-top: 0.25rem;
  margin-right: 0.24rem;
  font-size: 0.29rem;
  color: #666666;
}
.P_option_active {
  border: 0.02rem solid #3693ff;
  padding: 0 0.19rem;
  color: #3693ff;
}
.isHotJob {
  position: absolute;
  right: 0;
  top: 0;
  width: 0.83rem;
  height: 0.83rem;
  background: url(~@/assets/h5/h5_worry.png) no-repeat;
  background-size: cover;
}
::v-deep .van-dropdown-menu__title--active {
  color: #5d89ff;
}
.share {
  width: 1rem;
  height: 1rem;
  background: transparent url(~@/assets/h5_switch.png);
  position: fixed;
  bottom: 2.3rem;
  right: 0;
  z-index: 99;
  background-size: cover;
}
.p-bottom {
  position: fixed;
  bottom: 0;
  left: 0;
  background: #1988f9;
  text-align: center;
  width: 100%;
  font-size: 0.3rem;
  padding: 0.15rem 0;
  color: #fff;
}
::v-deep .van-dropdown-menu__bar {
  background: transparent;
  box-shadow: 0 0 0 #fff;
  .van-ellipsis {
    color: #333;
    font-size: 0.32rem;
  }
  .tab-icon {
    position: relative;
    height: 0.4rem;
    top: 0.08rem;
  }
}
::v-deep .van-tabs {
  .van-tab {
    padding: 0 0.3rem;
    flex: none;
  }
  .van-tab--active {
    .van-tab__text {
      color: #377dff;
    }
  }
  .van-tabs__line {
    background: #377dff;
  }
  .van-tab__text {
    font-size: 0.32rem;
    color: #999999;
  }
}
.loading {
  text-align: center;
  color: #999;
  font-size: 12px;
  padding: 30px 0;
  .el-icon {
    font-size: 30px;
  }
}
</style>
